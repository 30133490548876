import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, navigate } from "gatsby";
import PageWrapper from "../components/PageWrapper";
import network from "../../constants/Network";
import GlobalContext from "../context/GlobalContext";
import axios from "axios";
import { Modal } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { format, parseISO } from 'date-fns';

const UserCertificates = ({ location }) => {

  const params = new URLSearchParams(location.search);
  const isMountedRef = useRef(false);

  const gContext = useContext(GlobalContext);
  const userId = gContext?.user?.id || '';
  const [userCertificates, setUserCertificates] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [isFocused, setIsFocused] = useState('');
  const [errors, setErrors] = useState({});
  const [modalButtonGroup, setModalButtonGroup] = useState('createCertificate');
  const fileInputRef = useRef(null);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    register_number: "",
    file: null,
    document_name: "",
  });

  const imageBaseUrl = `${network.serverip}/images/`;

  const handleCertificateCreate = () => {
    setModalVisible(true);
    setModalButtonGroup('createCertificate');
    setFormData({
      id: "",
      name: "",
      register_number: "",
      file: null,
      document_name: "",
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const handleCertificateEdit = (data) => {
    setModalButtonGroup('editCertificate');
    setModalVisible(true);
    setFormData({
      id: data?.id || '',
      name: data?.name || '',
      register_number: data?.register_number || '',
      file: null,
      document_name: data?.document_name || '',
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const handleClose = () => {
    setModalVisible(false);
    setFormData({
      id: "",
      name: "",
      register_number: "",
      file: null,
      document_name: "",
    });
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setErrors({});
    setIsFocused('');
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleWordChange = (word) => {
    return word
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  const fetchUserCertificates = async () => {

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    try {
      const response = await axios.get(`${network.serverip}/api/user-certificates-list-web/${userId}`);

      if (response.data.success && isMountedRef.current) {

        const userCertificatesData = response.data.data || [];

        setUserCertificates(userCertificatesData);
        console.log('This is the fetched user certificates:', userCertificatesData);

      } else if (!response.data.success) {
        if (isMountedRef.current) {
          setUserCertificates([]);
        }
        console.error('Failed to fetch user certificates');
      }
    } catch (error) {
      if (isMountedRef.current) {
        setUserCertificates([]);
      }
      console.error('Error fetching user certificates:', error);
    }
  };

  const handleFileDownload = async (fileName, name) => {
    try {
      // Define the document URL
      const documentUrl = `${network.serverip}/storage/${fileName}`;
      console.log('Downloading from URL:', documentUrl);

      // Fetch the document from the server using Axios
      const response = await axios.get(documentUrl, {
        responseType: 'blob' // Important: This ensures that the response is treated as a Blob
      });

      // Check if the response is successful
      if (response.status !== 200) {
        throw new Error('Failed to download document');
      }

      // Convert the response data into a Blob URL
      const fileUrl = window.URL.createObjectURL(response.data);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = fileUrl;
      a.download = name; // Set the file name

      // Programmatically trigger a click event to download the file
      document.body.appendChild(a);
      a.click();

      // Cleanup: Remove the anchor and revoke the Blob URL
      a.remove();
      window.URL.revokeObjectURL(fileUrl);

      console.log(`Download complete: ${name}`);

      // Optionally, show a notification after the download completes
      alert(`The file "${name}" has been downloaded successfully.`);

    } catch (error) {
      console.error('Error downloading the document:', error);

      // Optional: Show a notification in case of failure
      alert(`Failed to download the file "${name}". Please try again.`);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setModalVisible(false);
        setFormData({
          id: "",
          name: "",
          register_number: "",
          file: null,
          document_name: "",
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSaveAndAddAnother = async (e) => {
    e.preventDefault();
    try {
      const uploadResult = await handleSubmit();
      if (uploadResult.success) {
        setFormData({
          id: "",
          name: "",
          register_number: "",
          file: null,
          document_name: "",
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setErrors({});
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleDocumentPick = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        file: file,
        document_name: file.name
      });
    }
  };


  const handleSubmit = async () => {

    console.log('This is the formData', formData);

    setErrors({});

    if (!userId) {
      gContext.toggleSignInModal();
      return
    }

    let isValid = true;

    if (!formData.name) {
      setErrors(prevErrors => ({
        ...prevErrors,
        title: 'Please enter name'
      }));
      isValid = false;
    }

    if (!formData.register_number) {
      setErrors(prevErrors => ({
        ...prevErrors,
        procedureCount: 'Please enter register number'
      }));
      isValid = false;
    }

    if (!isValid) {
      return { success: false };
    }

    try {

      formData.user_id = userId;

      const response = await axios.post(
        `${network.serverip}/api/user-certificate-upload-web`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.data.success && isMountedRef.current) {
        console.log(`User certificate uploaded successfully:`, response.data);
        fetchUserCertificates();
        return { success: true };
      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
        return { success: false };
      }

    } catch (error) {
      console.error('Error uploading user certificate:', error);

      if (error.response) {

        if (error.response.data.error) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            ...error.response.data.error,
          }));
        }

        if (error.response.data.message) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

        return { success: false };

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));

        return { success: false };

      }
    }
  }

  const handleCertificateDelete = async (data) => {

    console.log('Handle certificate delete pressed:', data);

    if (!userId) {
      return;
    }

    setErrors('');

    try {

      const response = await axios.delete(`${network.serverip}/api/user-certificate-delete-web`, {
        params: {
          user_id: userId,
          id: data?.id,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log('This is the response:', response);

      if (response.data.success) {
        fetchUserCertificates();
        setModalVisible(false);
        setFormData({
          id: "",
          name: "",
          register_number: "",
          file: null,
          document_name: "",
        });
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        setErrors({});
      }

    } catch (error) {
      console.log('This is the delete certificate catch response:', error.response);

      if (error.response) {
        if (error.response.data.errors) {
          setErrors(prevErrors => ({
            ...prevErrors,
            ...error.response.data.errors,
          }));
          return
        }

        if (error.response.data.message) {
          setErrors(prevErrors => ({
            ...prevErrors,
            uploadError: error.response.data.message,
          }));
        }

      } else {
        setErrors(prevErrors => ({
          ...prevErrors,
          uploadError: 'Something went wrong, please try again',
        }));
      }
    }
  };

  useEffect(() => {
    if (!userId) {
      console.log('Waiting for the user id');
      return;
    }

    isMountedRef.current = true;

    fetchUserCertificates();

    return () => {
      isMountedRef.current = false;
    };
  }, [userId]);


  const styles = UserCertificatesStyling();

  return (
    <>
      <PageWrapper headerConfig={{ button: "profile" }}>
        <div className="bg-default-2 pt-32 pt-lg-25 pb-13 pb-xxl-32">
          <div className="container">
            {/* <!-- back Button --> */}
            <div className="row justify-content-center">
              <div className="col-10 dark-mode-texts">
                <div className="mb-9">
                  <button style={{ border: 'none', backgroundColor: 'transparent' }} onClick={() => {
                    if (window.history.length > 1) {
                      navigate(-1);
                    } else {
                      navigate('/');
                    }
                  }} className="d-flex align-items-center ml-4">
                    {" "}
                    <i className="icon icon-small-left bg-white circle-40 mr-5 font-size-7 text-black font-weight-bold shadow-8"></i>
                    <span className="text-uppercase font-size-3 font-weight-bold text-gray">
                      Back
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="row d-flex justify-content-center">

              <div className="col-10">
                <div className="bg-white rounded-4 shadow-9">

                  <div className="border-top p-5 pl-xs-12 pr-xs-12 pt-7 pb-5">
                    <button
                      onClick={() => { }}
                      className="d-flex justify-content-between align-items-center mb-7 mt-5 w-100"
                      style={styles.collapsibleHeadingButton}
                    >
                      <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                        Upload Certificate
                      </h4>
                    </button>

                    <div>
                      {userCertificates && userCertificates.length > 0 ? (
                        userCertificates.map((cer, index) => {

                          return (
                            <div className="w-100 border-top" key={index}>
                              <div className="d-flex align-items-start pr-11 mb-6 mt-6 flex-wrap flex-sm-nowrap">
                                <div className="d-flex align-items-start mt-0" style={{ width: '3rem' }}>
                                  <i
                                    className="fas fa-id-card font-size-4 text-success"
                                  ></i>
                                </div>

                                <div className="w-100">
                                  <div className="d-flex justify-content-between w-100">
                                    <div className="d-flex align-items-center justify-content-md-start flex-wrap">
                                      <p className="mb-0 font-size-4 text-black-2 font-weight-semibold text-upper">
                                        {cer?.name}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-start">
                                      <button
                                        style={styles.editButton}
                                        onClick={() => { handleCertificateEdit(cer) }}
                                      >
                                        <i className="fas fa-pencil-alt text-success font-size-4"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <p className="font-size-4 text-default-color">
                                    {`Register Number: ${cer?.register_number}`}
                                  </p>
                                  <div className="d-flex align-items-center justify-content-md-end flex-wrap">
                                    <button
                                      style={styles.downloadButton}
                                      className="d-flex"
                                      onClick={() => handleFileDownload(cer.file_name, cer.document_name)}
                                    >
                                      <p className="mb-0 font-size-4 text-dodger font-weight-semibold text-upper">
                                        {cer?.document_name}
                                      </p>
                                      <i className="fas fa-download font-size-4 text-dodger ml-2"></i>
                                    </button>
                                  </div>

                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <p>No certificates available.</p>
                      )}
                    </div>
                    <div className="w-100 pt-9 mb-9 border-top pr-11">
                      <button
                        className="w-100 d-flex align-items-center justify-content-between"
                        onClick={() => { handleCertificateCreate() }}
                        style={styles.addButton}
                      >
                        <p className="font-size-4 text-dodger font-weight-semibold mb-0">
                          {userCertificates.length > 0 ? 'Add another certificate' : 'Add certificate'}
                        </p>
                        <i className="fas fa-plus text-success font-size-4"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
      <Modal
        size="lg"
        centered
        show={modalVisible}
      >
        <Modal.Body className="p-0">
          <button
            type="button"
            className="circle-32 btn-reset bg-white pos-abs-tr mt-n6 mr-lg-n6 focus-reset shadow-10"
            onClick={handleClose}
          >
            <i className="fas fa-times"></i>
          </button>
          <form onSubmit={handleSave} className="bg-white rounded-8 overflow-hidden">
            <div className="p-10 w-100">
              <div className="d-flex justify-content-center border-bottom border-mercury pb-2">
                <h4 className="font-size-6 text-black-2 font-weight-semibold mb-0">
                  {modalButtonGroup === 'createCertificate'
                    ? 'Add Certificate '
                    : 'Edit Certificate'}
                </h4>
              </div>
              <div className="w-100 border-bottom border-mercury mb-5">

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="name"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Name:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the name"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onFocus={() => setIsFocused('name')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'name' ? styles.focussedInput : {}}
                  />
                  {errors?.name ? <p className="font-size-3 text-danger">
                    {errors?.name}
                  </p> : null}
                </div>

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="register_number"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    Register number:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter the register number"
                    id="register_number"
                    name="register_number"
                    value={formData.register_number}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onFocus={() => setIsFocused('register_number')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'register_number' ? styles.focussedInput : {}}
                  />
                  {errors?.register_number ? <p className="font-size-3 text-danger">
                    {errors?.register_number}
                  </p> : null}
                </div>

                {modalButtonGroup === 'editCertificate' && !formData.file ? (
                  <div className="form-group mt-9 w-100">
                    <label
                      htmlFor="documentUpload"
                      className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                    >
                      Uploaded certificate:
                    </label>

                    <input
                      type="text"
                      className="form-control"
                      id="document_name"
                      name="document_name"
                      value={formData.document_name}
                      readOnly
                    />

                    {errors?.file ? <p className="font-size-3 text-danger">
                      {errors?.file}
                    </p> : null}
                  </div>
                ) : null}

                <div className="form-group mt-9 w-100">
                  <label
                    htmlFor="documentUpload"
                    className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                  >
                    {modalButtonGroup === 'createCertificate' ? 'Upload certificate:' : 'Change uploaded certificate:'}
                  </label>

                  <input
                    type="file"
                    className="form-control-file"
                    name="documentUpload"
                    onChange={handleDocumentPick}
                    id="documentUpload"
                    ref={fileInputRef}
                    onFocus={() => setIsFocused('file')}
                    onBlur={() => setIsFocused('')}
                    style={isFocused === 'file' ? styles.focussedInput : {}}
                  />

                  {errors?.file ? <p className="font-size-3 text-danger">
                    {errors?.file}
                  </p> : null}
                </div>

              </div>

              <div className="w-100 d-flex justify-content-end">
                {modalButtonGroup === 'createCertificate' ? (
                  <div className="w-100 d-flex flex-column flex-sm-row justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveAndAddAnother}
                      type="button"
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Save and add another
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                ) : (
                  <div className="w-100 d-flex flex-column flex-sm-row justify-content-end">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="btn btn-outline-success h-px-48 rounded-3 mb-5 px-5">
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={() => { handleCertificateDelete(formData) }}
                      className="btn btn-outline-danger h-px-48 rounded-3 mb-5 px-5 mx-5">
                      Delete
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success h-px-48 rounded-3 mb-5 px-5">
                      Save
                    </button>
                  </div>
                )}
              </div>

              <div className="w-100 d-flex justify-content-center">
                {errors?.uploadError ? <p className="font-size-3 text-danger">
                  {errors?.uploadError}
                </p> : null}
              </div>

            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default UserCertificates;

function UserCertificatesStyling() {
  const styles = {
    collapsibleHeadingButton:
    {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    downloadButton: {
      border: 'none',
      backgroundColor: 'transparent',
      outline: 'none',
      padding: 0,
    },
    imageViewButton: {
      outline: 'none',
      border: 'none',
      background: 'none',
      padding: '0',
    },
    addButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    editButton: {
      border: 'none',
      outline: 'none',
      background: 'transparent',
    },
    suggestionContainer: {
      maxHeight: "200px",
      overflowY: "auto",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "5px",
    },
    suggestionItemStyle: {
      cursor: "pointer",
      backgroundColor: "white",
      border: "none",
      outline: 'none',
    },
    focussedInput: {
      borderColor: '#3498db',
      color: '#333',
      outline: 'none',
      borderWidth: '2px',
      boxShadow: '0 0 5px rgba(52, 152, 219, 0.5)',
    }
  };

  return styles;
}

